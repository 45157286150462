import type { VFC } from "react";
import React from "react";

import { Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useTranslation } from "react-i18next";
import { PLACEHOLDER } from "shared/constants";
import { getOrdinalSuffix } from "shared/utils/grammar";

import DataItem from "./DataItem";
import useStyles from "./styles";

import type { AlumnusDetail } from "shared/types";

interface Props {
  alumnus: AlumnusDetail;
}

const changeName = (alumnus: AlumnusDetail, language: string) => {
  if (language == "ja" || alumnus.nameEn == "" || alumnus.nameEn == null) {
    return alumnus.name;
  }
  return alumnus.nameEn;
};
const changeCompanyName = (alumnus: AlumnusDetail, language: string) => {
  if (
    language == "ja" ||
    alumnus.companyNameEn == "" ||
    alumnus.companyNameEn == null
  ) {
    return alumnus.companyName;
  }
  return alumnus.companyNameEn;
};
const changePrefecture = (alumnus: AlumnusDetail, language: string) => {
  if (
    language == "ja" ||
    alumnus.officePrefectureEn == "" ||
    alumnus.officePrefectureEn == null
  ) {
    return alumnus.officePrefecture;
  }
  return alumnus.officePrefectureEn;
};
const changeJobType = (alumnus: AlumnusDetail, language: string) => {
  if (
    language == "ja" ||
    alumnus.jobTypeEn == "" ||
    alumnus.jobTypeEn == null
  ) {
    return alumnus.jobType;
  }
  return alumnus.jobTypeEn;
};
const changeMessage = (alumnus: AlumnusDetail, language: string) => {
  if (
    language == "ja" ||
    alumnus.messageEn == "" ||
    alumnus.messageEn == null
  ) {
    return alumnus.message;
  }
  return alumnus.messageEn;
};
const changeCollegeGradFaculty = (alumnus: AlumnusDetail, language: string) => {
  if (
    language == "ja" ||
    alumnus.collegeGradFacultyEn == "" ||
    alumnus.collegeGradFacultyEn == null
  ) {
    return alumnus.collegeGradFaculty;
  }
  return alumnus.collegeGradFacultyEn;
};
const changeCollegeGradDepartment = (
  alumnus: AlumnusDetail,
  language: string,
) => {
  if (
    language == "ja" ||
    alumnus.collegeGradDepartmentEn == "" ||
    alumnus.collegeGradDepartmentEn == null
  ) {
    return alumnus.collegeGradDepartment;
  }
  return alumnus.collegeGradDepartmentEn;
};

const View: VFC<Props> = ({ alumnus }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const langs = [
    alumnus.langJaFlag && `${t("detail.language_ja")}`,
    alumnus.langEnFlag && `${t("detail.language_en")}`,
  ].filter((lang) => !!lang);

  const contactLang = !!langs.length ? (
    <>
      {langs.map((lang, index) => (
        <Typography key={index} component="span" className={classes.spacing}>
          {lang}
        </Typography>
      ))}
    </>
  ) : null;

  return (
    <div className={classes.root}>
      <div className={classes.personal}>
        <AccountCircleIcon className="avater" />
        <div className="body">
          <Typography component="div" className="primary">
            {changeName(alumnus, i18n.language)}
          </Typography>
          <Typography component="div" className="secondary">
            {`${alumnus.collegeTerm || PLACEHOLDER}${getOrdinalSuffix(
              alumnus.collegeTerm,
              i18n.language,
            )}${t("search.field.period")}`}
          </Typography>
        </div>
      </div>

      <div className={classes.content}>
        <Typography component="div" className="header">
          {t("detail.label.campus")}
        </Typography>
        <div className="body">
          <DataItem
            name={t("detail.label.faculty_department")}
            value={`${changeCollegeGradFaculty(alumnus, i18n.language) || ""} ${
              changeCollegeGradDepartment(alumnus, i18n.language) || ""
            }`.trim()}
          />
          <DataItem
            name={t("detail.label.fraduation_year")}
            value={`${alumnus.collegeGradYear || PLACEHOLDER} ${t(
              "detail.label.term",
            )}`}
          />
          <DataItem
            name={t("detail.label.seminar")}
            value={alumnus.collegeSeminar}
          />
        </div>
      </div>

      <div className={classes.content}>
        <Typography component="div" className="header">
          {t("detail.label.work")}
        </Typography>
        <div className="body">
          <div className="subheader">
            <Typography component="div" className="primary">
              {changeCompanyName(alumnus, i18n.language)}
            </Typography>
            <Typography component="div" className="secondary">
              {`${alumnus.entranceYear ?? PLACEHOLDER} ${t(
                "detail.label.year",
              )} ${
                alumnus.entranceMonth
                  ? `${alumnus.entranceMonth} ${t("detail.label.month")}`
                  : ""
              }${t("detail.label.joining")}`}
            </Typography>
          </div>
          <DataItem
            name={t("detail.label.affiliation")}
            value={alumnus.belong}
          />
          <DataItem
            name={t("search.field.area")}
            value={changePrefecture(alumnus, i18n.language)}
          />
          <DataItem
            name={t("search.field.job")}
            value={changeJobType(alumnus, i18n.language)}
          />
          <DataItem name={t("search.field.director")} value={alumnus.post} />

          {alumnus.message && (
            <div className={classes.box}>
              <Typography className="title">
                {t("detail.label.message")}
              </Typography>
              <Typography className="body">
                {changeMessage(alumnus, i18n.language)}
              </Typography>
            </div>
          )}
        </div>
      </div>

      <div className={classes.content}>
        <Typography component="div" className="header">
          {t("detail.label.contact")}
        </Typography>
        <div className="body">
          <DataItem
            name={t("detail.label.email")}
            value={
              !!alumnus.email && (
                <a href={`mailto:${alumnus.email}`}>{alumnus.email}</a>
              )
            }
          />
          <DataItem
            name={t("detail.label.phone")}
            value={
              !!alumnus.tel && <a href={`tel:${alumnus.tel}`}>{alumnus.tel}</a>
            }
          />
          <DataItem
            name={t("detail.label.supported_languages")}
            value={contactLang}
          />
          <DataItem
            name={t("detail.label.Supported_datetime")}
            value={alumnus.contactTime}
          />
        </div>
      </div>
    </div>
  );
};

export default View;
