import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listCount: {
      marginLeft: theme.spacing(2),
      fontSize: 11,
    },
    heading: {
      fontSize: 11,
    },
  }),
);

export default useStyles;
