import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as alumniApi from "shared/services/alumniApi";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { Loadable, Occupation } from "shared/types";

const occupationAdapter = createEntityAdapter<Occupation>({});

const loadable = {
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  data: [],
};
const initialState = occupationAdapter.getInitialState<{
  status: string;
  error?: string;
  occupation: string;
  listOccupation: Loadable<Occupation[]>;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  occupation: "",
  listOccupation: { ...loadable },
});

export const searchOccupation = createAsyncThunk(
  "occupations/occupationsFetched",
  async (thunkAPI) => {
    return await alumniApi.fetchOccupations();
  },
);

export const changeLanguage = (occupation: Occupation, language: string) => {
  if (language == "ja") {
    return occupation.name;
  }
  return occupation.nameEn;
};

const occupationsSlice = createSlice({
  name: "occupations",
  initialState: initialState,
  reducers: {
    OccupationChanged: (state, action: PayloadAction<string>) => {
      state.occupation = action.payload;
    },
    clear: (state) => {
      state.occupation = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchOccupation.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchOccupation.fulfilled, (state, { payload }) => {
        if (payload) occupationAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchOccupation.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const occupationsActions = occupationsSlice.actions;

export const occupationsSelectors = occupationAdapter.getSelectors<RootState>(
  (state) => state.occupation,
);

export default occupationsSlice.reducer;
