import type { VFC } from "react";
import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  description: string;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const AlertDialog: VFC<Props> = ({
  title,
  description,
  open,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t("alert.label.cancel")}
        </Button>
        <Button onClick={onOk} color="primary" autoFocus>
          {t("alert.label.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
