import {
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { StudentStatus } from "shared/types";

const alumnisEngAdapter = createEntityAdapter<StudentStatus>({});

const initialState = alumnisEngAdapter.getInitialState<{
  status: string;
  error?: string;
  alumniEng: boolean;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  alumniEng: false,
});

export const changeLanguage = (alumniEng: StudentStatus, language: string) => {
  if (language == "ja") {
    return alumniEng.name;
  }
  return alumniEng.nameEn;
};

const alumnisEngSlice = createSlice({
  name: "alumnisEng",
  initialState: initialState,
  reducers: {
    AlumniEngChanged: (state, action: PayloadAction<boolean>) => {
      state.alumniEng = action.payload;
    },
    clear: (state) => {
      state.alumniEng = false;
    },
  },
});

export const alumnisEngActions = alumnisEngSlice.actions;

export const alumnisEngSelectors = alumnisEngAdapter.getSelectors<RootState>(
  (state) => state.alumniEng,
);

export default alumnisEngSlice.reducer;
