import { configureStore } from "@reduxjs/toolkit";
import alumniEng from "features/AlumniEng/store";
import alumni from "features/AlumniList/store";
import companyEng from "features/CompanyEng/store";
import companies from "features/CompanyList/store";
import entrance from "features/Entrance/store";
import graduation from "features/Graduation/store";
import occupation from "features/Occupation/store";
import prefecture from "features/Prefecture/store";
import school from "features/School/store";
import search from "features/Search/store";
import workLocation from "features/WorkLocation/store";

import shell from "./Shell/store";

export const store = configureStore({
  reducer: {
    shell,
    search,
    companies,
    alumni,
    prefecture,
    workLocation,
    entrance,
    occupation,
    graduation,
    school,
    companyEng,
    alumniEng,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
