import type { ReactNode, VFC } from "react";
import React from "react";

import Drawer from "./Drawer";
import useStyles from "./styles";

interface Props {
  children: ReactNode;
}

const Shell: VFC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Drawer />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Shell;
