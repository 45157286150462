import { handleResponse } from "shared/utils/apiUtils";

import type {
  Alumnus,
  AlumnusDetail,
  EntrancePeriod,
  GraduationFaculty,
  Occupation,
  StudentStatus,
  WorkLocation,
} from "shared/types";

export const searchAlumni = (params: URLSearchParams): Promise<Alumnus[]> =>
  fetch("/api/alumni/SearchList?" + params)
    .then(handleResponse)
    .then((response) => response.json());

export const fetchAlumnus = (id: string): Promise<AlumnusDetail> =>
  fetch("/api/alumni/" + id)
    .then(handleResponse)
    .then((response) => response.json());

export const fetchOccupations = (): Promise<Occupation[]> => {
  return fetch("/api/Occupations/Search")
    .then(handleResponse)
    .then((response) => response.json());
};

export const fetchWorkLocations = (): Promise<WorkLocation[]> => {
  return fetch("/api/Prefectures/Search")
    .then(handleResponse)
    .then((response) => response.json());
};

export const fetchEntrances = (): Promise<EntrancePeriod[]> => {
  return fetch("/api/Entrances/Search")
    .then(handleResponse)
    .then((response) => response.json());
};

export const fetchGraduations = (): Promise<GraduationFaculty[]> => {
  return fetch("/api/Faculties/Search")
    .then(handleResponse)
    .then((response) => response.json());
};

export const fetchSchools = (): Promise<StudentStatus[]> => {
  return fetch("/api/Schools/Search")
    .then(handleResponse)
    .then((response) => response.json());
};
