import type { VFC } from "react";

import { ListItem as MuiListItem, Typography } from "@material-ui/core";
import { useAppSelector } from "App/hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import DataItem from "shared/components/DataItem";
import { PLACEHOLDER } from "shared/constants";


import useStyles from "./styles";
import { companiesSelectors } from "../store";

import type { EntityId } from "@reduxjs/toolkit";
import type { Company } from "shared/types";

const ListItem: VFC<{ id: EntityId }> = ({ id }) => {
  const classes = useStyles();
  const history = useHistory();
  const company = useAppSelector((state) =>
    companiesSelectors.selectById(state, id),
  );
  const { t, i18n } = useTranslation();

  if (!company) return null;

  const handleClick = () => {
    history.push({
      pathname: "/alumni",
      search: new URLSearchParams({ companyId: id.toString() }).toString(),
    });
  };

  const setCompany = (company: Company) => {
    if (
      i18n.language == "ja" ||
      company.nameEn == "" ||
      company.nameEn == null
    ) {
      return company.name;
    }
    return company.nameEn;
  };
  const setPrefecture = (company: Company) => {
    if (i18n.language == "ja") {
      if (company.city == "" || company.city == null) {
        return company.prefecture;
      }
      if (company.prefecture == "" || company.prefecture == null) {
        return company.city;
      }
      return company.prefecture + " " + company.city;
    }
    // バックエンド側で英語の市・都道府県がない場合は日本語の市・都道府県をセット済
    if (company.cityEn == "" || company.cityEn == null) {
      return company.prefectureEn;
    }
    if (company.prefectureEn == "" || company.prefectureEn == null) {
      return company.cityEn;
    }
    return company.prefectureEn + " " + company.cityEn;
  };
  const setIndustry = (company: Company) => {
    if (
      i18n.language == "ja" ||
      company.industryEn == "" ||
      company.industryEn == null
    ) {
      return company.industry;
    }
    return company.industryEn;
  };

  return (
    <MuiListItem
      divider
      alignItems="flex-start"
      onClick={handleClick}
      className={classes.root}
    >
      <Typography component="div" className={classes.title}>
        {setCompany(company)}
        {company.listed && (
          <div className={classes.tag}>{t("search.field.listing")}</div>
        )}
      </Typography>

      <DataItem
        name={t("search.field.office")}
        value={`${setPrefecture(company)}`.trim()}
      />
      <DataItem
        name={t("search.field.industry")}
        value={setIndustry(company)}
      />
      <DataItem
        name={t("search.field.employees")}
        value={`${company.numberOfEmployees || PLACEHOLDER} ${t(
          "search.field.people",
        )}`}
      />
      <DataItem
        name={t("search.field.alumnies")}
        value={`${company.enrolledCount || PLACEHOLDER} ${t(
          "search.field.people",
        )}`}
      />
    </MuiListItem>
  );
};

export default ListItem;
