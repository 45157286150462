export const handleResponse = (response: Response) => {
  if (response.ok) return response;

  if (response.status === 401) {
    sessionStorage.removeItem("user");
    window.location.reload();
  }

  throw new Error(`Response not ok: ${response.status}`);
};
