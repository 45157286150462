import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

/**
 * サポートしている言語
 * i18nextから取得するようにしたいが、i18nextではサポートしている言語の取得ができないため自前で定義している。
 * 参考： https://github.com/i18next/i18next/issues/1068
 */
export const supportedLanguages = ["en", "ja"] as const;

/**
 * デフォルトの名前空間
 */
export const defaultNS = "translation";

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === "development",
    defaultNS: defaultNS,
    detection: { order: ["navigator"] },
    fallbackLng: supportedLanguages,
    // i18nの初期化前にreactコンポーネントから読み出されることがあるので、同期的に初期化する
    // が、実際にはバックエンド（i18next-http-backend）が動機読み込みに非対応なのであまり意味はないっぽい
    // 少なくともUIの表示には影響がないので、とりあえずはこのままにしておく
    initImmediate: false,
    returnNull: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
