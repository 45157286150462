import { makeStyles, createStyles } from "@material-ui/core/styles";
import { HEADER_HEIGHT } from "shared/constants";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      position: "fixed",
      width: "100%",
      top: "0",
      backgroundColor: "#fff",
      zIndex: 10,
      padding: `0px ${theme.spacing(0.5)}px`,
      height: HEADER_HEIGHT,
    },
    menuLanguage: {
      // 600px以上
      "@media (min-width:600px)": {
        marginLeft: "40px",
      },
      // 599px以下
      "@media (max-width:599px)": {
        marginLeft: "auto",
      },
    },
    menuButton: {
      // 600px以上
      "@media (min-width:600px)": {
        //marginLeft: "50px",
        display: "none",
      },
      // 599px以下
      "@media (max-width:599px)": {
        marginLeft: "auto",
      },
    },
    logo: {
      marginRight: theme.spacing(1),
    },
  }),
);

export default useStyles;
