import type { VFC } from "react";
import React from "react";

import { useTranslation } from "react-i18next";

const Logo: VFC = () => {
  const { t } = useTranslation();
  return (
    <img
      src="/images/cn.jpg"
      alt={`${t("logo.label.carinavi_kun")}`}
      width="35"
    />
  );
};

export default Logo;
