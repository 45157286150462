import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as companiesApi from "shared/services/companiesApi";

import type { RootState } from "App/store";
import type { Company } from "shared/types";

const companiesAdapter = createEntityAdapter<Company>({
  sortComparer: (a, b) => {
    if (a.kana == null) return 1;
    if (b.kana == null) return -1;
    return a.kana.localeCompare(b.kana, "ja");
  },
});

const initialState = companiesAdapter.getInitialState<{
  status: string;
  error?: string;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
});

export const searchCompany = createAsyncThunk(
  "companies/companiesFetched",
  async (searchParams: string, thunkAPI) => {
    const params = new URLSearchParams(searchParams);
    return await companiesApi.searchCompanies(params);
  },
);

const companiesSlice = createSlice({
  name: "companies",
  initialState: initialState,
  reducers: {
    clear: (state) => {
      companiesAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCompany.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchCompany.fulfilled, (state, { payload }) => {
        if (payload) companiesAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchCompany.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const companiesActions = companiesSlice.actions;

export const companiesSelectors = companiesAdapter.getSelectors<RootState>(
  (state) => state.companies,
);

export default companiesSlice.reducer;
