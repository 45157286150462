import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: theme.spacing(3),
    },
  }),
);

export default useStyles;
