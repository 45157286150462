import type { VFC } from "react";
import React from "react";

import { Button, Divider, Typography } from "@material-ui/core";
import i18n from "i18n";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import LanguageChangeButton from "shared/components/LanguageChangeButton";
import { setConsent } from "shared/services/consent";

import useStyles from "./styles";

const Consent: VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    setConsent();
    window.location.href = "/#lang=" + i18n.language;
  };

  return (
    <main className={classes.root}>
      <Helmet>
        <title>{t("title.consent")}</title>
      </Helmet>
      <img
        src="/images/applogo.jpg"
        alt={`${t("consent.logo")}`}
        width="245px"
        height="260px"
        className={classes.logo}
      />

      <header>
        <LanguageChangeButton />
      </header>

      <Typography variant="h5" className={classes.spacing}>
        {t("consent.agreements.no1")}
      </Typography>
      <Typography className={classes.spacing}>
        {t("consent.agreements.no2")}
      </Typography>
      <ul className={classes.ul}>
        <li>
          <Typography>{t("consent.agreements.no3")}</Typography>
        </li>
        <li>
          <Typography>{t("consent.agreements.no4")}</Typography>
        </li>
        <li>
          <Typography>{t("consent.agreements.no5")}</Typography>
        </li>
        <li>
          <Typography>{t("consent.agreements.no6")}</Typography>
        </li>
      </ul>
      <Typography variant="body1" className={classes.spacing}>
        <a
          href={t("consent.agreements.privacy_link")}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("consent.agreements.no7")}
        </a>
      </Typography>
      <Divider className={classes.dividerSpacing} />
      <Typography className={classes.spacing}>
        {t("consent.analytics_agreements.no1")}
      </Typography>
      <Typography className={classes.spacing}>
        <Trans
          i18nKey="consent.analytics_agreements.no2"
          components={{
            1: (
              <a href={t("consent.analytics_agreements.analytics_policy_link")}>
                1
              </a>
            ),
            2: (
              <a href={t("consent.analytics_agreements.google_policy_link")}>
                2
              </a>
            ),
          }}
        />
      </Typography>

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleClick}
      >
        {t("consent.continuing")}
      </Button>
    </main>
  );
};

export default Consent;
