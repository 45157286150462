import type { VFC } from "react";
import React from "react";


import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { useTranslation } from "react-i18next";


import {
  occupationsSelectors,
  occupationsActions,
  changeLanguage,
} from "./store";
import useStyles from "./styles";

import type { Occupation } from "shared/types";

const FeachOccupation: VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const occupations = useAppSelector(occupationsSelectors.selectAll);
  const occupation = useAppSelector((state) => state.occupation.occupation);
  const { t, i18n } = useTranslation();

  const handleChangeOccipation = (value: string) => {
    dispatch(occupationsActions.OccupationChanged(value));
  };
  return (
    <div className={classes.combo}>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <TextField
          label={t("search.field.job")}
          select
          id="outlined-select-occupation"
          margin="dense"
          defaultValue={occupation}
          value={occupation}
          variant="outlined"
          onChange={(e) => handleChangeOccipation(e.target.value as string)}
        >
          <MenuItem value="">
            <em>{t("search.option.none")}</em>
          </MenuItem>
          {occupations.map((occupation: Occupation, index) => (
            <MenuItem value={occupation.id} key={index}>
              {changeLanguage(occupation, i18n.language)}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default FeachOccupation;
