import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 1.5),
      maxWidth: "600px",
      margin: "0 auto",
    },
    spacing: {
      padding: theme.spacing(1),
    },
    dividerSpacing: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      display: "block",
      marginLeft: "auto",
    },
    logo: {
      display: "block",
      paddingBottom: theme.spacing(2),
      margin: "0 auto",
    },
    ul: {
      paddingLeft: "25px",
    },
  }),
);

export default useStyles;
