import type { VFC } from "react";
import React from "react";

import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Header from "shared/components/Header";

import useStyles from "./styles";


const Help: VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="root">
      <Helmet>
        <title>{t("help.title")}</title>
      </Helmet>
      <Header drillDown>
        <Typography variant="h6" component="h1">
          {t("menu.help")}
        </Typography>
      </Header>
      <section className={classes.view}>
        <Typography className={classes.spacing}>
          {t("help.text.no1")}
        </Typography>
        <Typography variant="h6" component="h2">
          {t("help.text.no2")}
        </Typography>
        <ul className={classes.ul}>
          <li>
            <Typography>{t("help.text.no3")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no4")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no5")}</Typography>
          </li>
        </ul>
        <Typography variant="h6" component="h2">
          {t("help.text.no6")}
        </Typography>
        <ul className={classes.ul}>
          <li>
            <Typography>{t("help.text.no7")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no8")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no9")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no10")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no11")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no12")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no13")}</Typography>
          </li>
          <li>
            <Typography>{t("help.text.no14")}</Typography>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Help;
