import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "../i18n";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import AlumniList from "features/AlumniList";
import Authentication from "features/Authentication";
import CompanyList from "features/CompanyList";
import Consent from "features/Consent";
import Detail from "features/Detail";
import Help from "features/Help";
import InvalidAccount from "features/InvalidAccount";
import NotFound from "features/NotFound";
import Search from "features/Search";
import { SnackbarProvider } from "notistack";

import AppRoute from "./Route";
import { store } from "./store";
import { defaultTheme } from "./themes/default";

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <CssBaseline />
        <Provider store={store}>
          <Router>
            <Switch>
              <AppRoute path="/" exact component={Search} />
              <AppRoute path="/companies" exact component={CompanyList} />
              <AppRoute path="/alumni" exact component={AlumniList} />
              <AppRoute path="/alumni/:id" exact component={Detail} />
              <AppRoute path="/alumni/:id" exact component={Detail} />
              <AppRoute path="/help" exact component={Help} />
              <Route path="/consent" exact component={Consent} />
              <Route path="/authentication" exact component={Authentication} />
              <Route path="/invalid-account" exact component={InvalidAccount} />
              <Route path="/notfound" exact component={NotFound} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
