import type { VFC } from "react";
import React from "react";


import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { useTranslation } from "react-i18next";


import { schoolsSelectors, schoolsActions, changeLanguage } from "./store";
import useStyles from "./styles";

import type { StudentStatus } from "shared/types";

const FeachSchool: VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const schools = useAppSelector(schoolsSelectors.selectAll);
  const school = useAppSelector((state) => state.school.school);
  const { t, i18n } = useTranslation();

  const handleChangeSchool = (value: string) => {
    dispatch(schoolsActions.SchoolChanged(value));
  };
  return (
    <div className={classes.combo}>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <TextField
          label={t("search.field.school")}
          select
          id="outlined-select-school"
          margin="dense"
          defaultValue={school}
          value={school}
          variant="outlined"
          onChange={(e) => handleChangeSchool(e.target.value as string)}
        >
          <MenuItem value="">
            <em>{t("search.option.none")}</em>
          </MenuItem>
          {schools.map((school: StudentStatus, index) => (
            <MenuItem value={school.id} key={index}>
              {changeLanguage(school, i18n.language)}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default FeachSchool;
