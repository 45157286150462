import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as companiesApi from "shared/services/companiesApi";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { Loadable, Prefecture } from "shared/types";

const prefecturesAdapter = createEntityAdapter<Prefecture>({});

const loadable = {
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  data: [],
};
const initialState = prefecturesAdapter.getInitialState<{
  status: string;
  error?: string;
  prefecture: string;
  listPrefecture: Loadable<Prefecture[]>;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  prefecture: "",
  listPrefecture: { ...loadable },
});

export const searchPrefecture = createAsyncThunk(
  "prefectures/prefecturesFetched",
  async (thunkAPI) => {
    return await companiesApi.fetchPrefectures();
  },
);

export const changeLanguage = (prefecture: Prefecture, language: string) => {
  if (language == "ja") {
    return prefecture.name;
  }
  return prefecture.nameEn;
};

const prefecturesSlice = createSlice({
  name: "prefectures",
  initialState: initialState,
  reducers: {
    PrefectureChanged: (state, action: PayloadAction<string>) => {
      state.prefecture = action.payload;
    },
    clear: (state) => {
      state.prefecture = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPrefecture.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchPrefecture.fulfilled, (state, { payload }) => {
        if (payload) prefecturesAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchPrefecture.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const prefecturesActions = prefecturesSlice.actions;

export const prefecturesSelectors = prefecturesAdapter.getSelectors<RootState>(
  (state) => state.prefecture,
);

export default prefecturesSlice.reducer;
