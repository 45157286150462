import type { VFC } from "react";
import { useEffect } from "react";

import { List, Typography } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import Header from "shared/components/Header";
import Loader from "shared/components/Loader";
import Error from "shared/components/LoadingError";
import { REQUEST_STATUS } from "shared/constants";

import ListItem from "./ListItem";
import { alumniSelectors, searchAlumni } from "./store";
import useStyles from "./styles";

const AlumniList: VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const alumniIds = useAppSelector(alumniSelectors.selectIds);
  const requestStatus = useAppSelector((state) => state.alumni.status);
  const isLoading = requestStatus === REQUEST_STATUS.PENDING;
  const isError = requestStatus === REQUEST_STATUS.ERROR;
  const { t } = useTranslation();

  useEffect(() => {
    if (history.action === "PUSH" || !alumniIds.length) {
      dispatch(searchAlumni(location.search));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, history]);

  const listCount = (
    <Typography
      variant="h6"
      className={classes.listCount}
      color="textSecondary"
    >
      {alumniIds.length} {t("list.results")}
    </Typography>
  );

  return (
    <div className="root">
      <Helmet>
        <title>{t("title.list")}</title>
      </Helmet>
      <Header drillDown>
        <Typography variant="h6" component="h1" className={classes.heading}>
          {t("search.field.list_seniors")}
        </Typography>
        {!isError && !isLoading && listCount}
      </Header>
      <section className="view">
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <Error />
        ) : (
          <List role="list" disablePadding>
            {alumniIds.map((id) => (
              <ListItem key={id} id={id} />
            ))}
          </List>
        )}
      </section>
    </div>
  );
};

export default AlumniList;
