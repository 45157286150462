import type { VFC } from "react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";



import {
  Divider,
  Drawer,
  Hidden,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  SwipeableDrawer,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  Help as HelpIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import { useAppSelector } from "App/hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import AlertDialog from "shared/components/AlertDialog";
import Symbol from "shared/components/Symbol";
import { HEADER_HEIGHT } from "shared/constants";

import useStyles from "./styles";
import { hideDrawer, showDrawer } from "../store";

const menuItems = [
  {
    text: "検索",
    icon: <SearchIcon />,
    to: "/",
    label: "menu.search",
  },
  {
    text: "ヘルプ",
    icon: <HelpIcon />,
    to: "/help",
    label: "menu.help",
  },
];

const AppDrawer: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const mobileOpen = useAppSelector((state) => state.shell.showDrawer);
  const [alertOpen, setAlertOpen] = useState(false);
  const { t } = useTranslation();

  const handleDrawerClose = () => dispatch(hideDrawer());
  const handleDrawerOpen = () => dispatch(showDrawer());
  const handleLogout = () => {
    fetch("/api/account/logout")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Response not ok: ${response.status}`);
        }

        sessionStorage.removeItem("user");
        window.location.href = "https://accounts.google.com/Logout";
      })
      .catch((err) => {
        enqueueSnackbar(`${t("error.message")}`, {
          variant: "error",
        });
      });
  };

  const drawer = (
    <div>
      <div
        className={classes.toolbar}
        style={{ minHeight: HEADER_HEIGHT, borderBottom: "1px solid #e8e8e8" }}
      >
        <Hidden smUp implementation="css">
          <Divider className={classes.scrollBar} />
          <IconButton onClick={handleDrawerClose} className={classes.close}>
            <CloseIcon />
          </IconButton>
        </Hidden>
      </div>
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={t(item.label)}
            component={Link}
            to={item.to}
            onClick={handleDrawerClose}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={t(item.label)} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className={classes.actions}>
        <Button variant="contained" onClick={() => setAlertOpen(true)}>
          {t("menu.logout")}
        </Button>
      </div>
      <div className={classes.symbol}>
        <Symbol />
      </div>
    </div>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          anchor="bottom"
          open={mobileOpen}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
      <AlertDialog
        title={t("menu.logout")}
        description={t("logout.text.google")}
        open={alertOpen}
        onOk={handleLogout}
        onCancel={() => setAlertOpen(false)}
      />
    </nav>
  );
};

export default AppDrawer;
