import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuLanguage: {
      // 600px以上
      "@media (min-width:600px)": {
        marginLeft: "40px",
      },
      // 599px以下
      "@media (max-width:599px)": {
        marginLeft: "auto",
      },
    },
  }),
);

export default useStyles;
