import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    title: {
      marginBottom: theme.spacing(0.5),
      fontWeight: "bold",
      color: theme.palette.text.primary,
      display: "flex",
      alignItems: "center",
    },
    tag: {
      marginLeft: theme.spacing(1),
      color: theme.palette.error.main,
    },
  }),
);

export default useStyles;
