import type { VFC } from "react";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import { hasConsented } from "shared/services/consent";

import Shell from "../Shell";

import type { User } from "shared/types";

interface Props {
  component: VFC;
  [x: string]: any;
}

const AppRoute: VFC<Props> = ({ component: Component, ...args }) => {
  const user: User = JSON.parse(sessionStorage.getItem("user") ?? "null");

  return (
    <Route
      component={() => {
        if (!user) {
          return <Redirect to="/authentication" />;
        }

        if (!hasConsented()) {
          return <Redirect to="/consent" />;
        }

        return (
          <Shell>
            <Component />
          </Shell>
        );
      }}
      {...args}
    />
  );
};

export default AppRoute;
