import type { VFC } from "react";
import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { useAppSelector } from "App/hooks";
import { useAppDispatch } from "App/hooks";
import { useTranslation } from "react-i18next";

import { companiesEngActions } from "./store";

const CompanyEng: VFC = () => {
  const companyEng = useAppSelector((state) => state.companyEng.companyEng);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(companiesEngActions.companyEngChanged(event.target.checked));
  };
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={companyEng}
            onChange={handleChange}
            name="checkedCompany"
            color="primary"
          />
        }
        label={t("search.field.company_eng")}
      />
    </FormGroup>
  );
};
export default CompanyEng;
