/**
 * 同意状態を保持するためのキー
 */
export const consentKey = "consent";

/**
 * 現在のタブorウインドウでの同意状態を取得します。
 * @returns 同意している場合はtrue、同意していない場合はfalse
 */
export const hasConsented = () => {
  const consent = sessionStorage.getItem(consentKey);
  return consent !== null && consent.toLocaleLowerCase() === "true";
};

/**
 * 現在のタブorウインドウでの同意状態を「同意」に設定します。
 */
export const setConsent = () => {
  sessionStorage.setItem(consentKey, "true");
};
