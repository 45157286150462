import React, { useRef, useState } from "react";

import { Button, Menu, MenuItem } from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";
import { supportedLanguages } from "i18n";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

/**
 * 言語変更ボタン
 */
const LanguageChangeButton: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { i18n, t } = useTranslation();

  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <div className={classes.menuLanguage}>
      <Button
        size="small"
        startIcon={<TranslateIcon color="action" />}
        ref={anchorEl}
        onClick={handleOpen}
      >
        {t("name")}
      </Button>
      <Menu
        anchorEl={anchorEl.current}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {supportedLanguages.map((l) => (
          <MenuItem onClick={() => handleChangeLanguage(l)} key={l}>
            {i18n.getFixedT(l)("name")}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageChangeButton;
