import type { VFC } from "react";
import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5),
    },
    spacing: {
      padding: theme.spacing(1),
    },
  }),
);

const NotFound: VFC = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Typography variant="h4" className={classes.spacing}>
        404 Not Found!
      </Typography>
      <Typography variant="body1" className={classes.spacing}>
        ページが見つかりません。
      </Typography>
      <Typography variant="body1" className={classes.spacing}>
        Page Not Found.
      </Typography>
      <Link to="/" className={classes.spacing}>
        ホームに戻る Return to the home
      </Link>
    </main>
  );
};

export default NotFound;
