import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  showDrawer: boolean;
} = {
  showDrawer: false,
};

const shellSlice = createSlice({
  name: "shell",
  initialState,
  reducers: {
    showDrawer(state) {
      state.showDrawer = true;
    },
    hideDrawer(state) {
      state.showDrawer = false;
    },
  },
});

export const { showDrawer, hideDrawer } = shellSlice.actions;

export default shellSlice.reducer;
