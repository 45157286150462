import type { VFC } from "react";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import Loader from "shared/components/Loader";

import type { User } from "shared/types";

const Authentication: VFC = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    var user = sessionStorage.getItem("user");

    if (!!user) {
      setIsAuthorized(true);
    } else {
      fetch("/api/account/me")
        .then((response) => {
          if (response.ok) return response;

          if (response.status === 401) {
            window.location.href = "/api/Account/ExternalLogin";
          }

          throw new Error(`Response not ok: ${response.status}`);
        })
        .then((response) => response.json())
        .then((data: User) => {
          sessionStorage.setItem("user", JSON.stringify(data));
          setIsAuthorized(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  if (isAuthorized) {
    return <Redirect to="/" />;
  }

  return <Loader />;
};

export default Authentication;
