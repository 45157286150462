import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    symbol: {
      display: "block",
      margin: "25px auto",
    },
  }),
);

export default useStyles;
