import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as alumniApi from "shared/services/alumniApi";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { Loadable, WorkLocation } from "shared/types";

const workLocationAdapter = createEntityAdapter<WorkLocation>({});

const loadable = {
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  data: [],
};
const initialState = workLocationAdapter.getInitialState<{
  status: string;
  error?: string;
  workLocation: string;
  listWorkLocation: Loadable<WorkLocation[]>;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  workLocation: "",
  listWorkLocation: { ...loadable },
});

export const searchWorkLocation = createAsyncThunk(
  "workLocations/workLocationsFetched",
  async (thunkAPI) => {
    return await alumniApi.fetchWorkLocations();
  },
);

export const changeLanguage = (
  workLocation: WorkLocation,
  language: string,
) => {
  if (language == "ja") {
    return workLocation.name;
  }
  return workLocation.nameEn;
};

const workLocationsSlice = createSlice({
  name: "workLocarions",
  initialState: initialState,
  reducers: {
    WorkLocationChanged: (state, action: PayloadAction<string>) => {
      state.workLocation = action.payload;
    },
    clear: (state) => {
      state.workLocation = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchWorkLocation.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchWorkLocation.fulfilled, (state, { payload }) => {
        if (payload) workLocationAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchWorkLocation.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const workLocationsActions = workLocationsSlice.actions;

export const workLocationsSelectors =
  workLocationAdapter.getSelectors<RootState>((state) => state.workLocation);

export default workLocationsSlice.reducer;
