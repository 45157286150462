import type { VFC } from "react";
import React from "react";


import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { useTranslation } from "react-i18next";


import {
  graduationsSelectors,
  graduationsActions,
  changeLanguage,
} from "./store";
import useStyles from "./styles";

import type { GraduationFaculty } from "shared/types";

const FeachGraduation: VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const graduations = useAppSelector(graduationsSelectors.selectAll);
  const graduation = useAppSelector((state) => state.graduation.graduation);
  const { t, i18n } = useTranslation();
  const handleChangeGraduation = (value: string) => {
    dispatch(graduationsActions.GraduationChanged(value));
  };
  return (
    <div className={classes.combo}>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <TextField
          label={t("search.field.graduation")}
          select
          id="outlined-select-graduation"
          margin="dense"
          defaultValue={graduation}
          value={graduation}
          variant="outlined"
          onChange={(e) => handleChangeGraduation(e.target.value as string)}
        >
          <MenuItem value="">
            <em>{t("search.option.none")}</em>
          </MenuItem>
          {graduations.map((graduation: GraduationFaculty, index) => (
            <MenuItem value={graduation.id} key={index}>
              {changeLanguage(graduation, i18n.language)}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default FeachGraduation;
