import {
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { StudentStatus } from "shared/types";

const companiesEngAdapter = createEntityAdapter<StudentStatus>({});

const initialState = companiesEngAdapter.getInitialState<{
  status: string;
  error?: string;
  companyEng: boolean;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  companyEng: false,
});

export const changeLanguage = (companyEng: StudentStatus, language: string) => {
  if (language == "ja") {
    return companyEng.name;
  }
  return companyEng.nameEn;
};

const companiesEngSlice = createSlice({
  name: "companiesEng",
  initialState: initialState,
  reducers: {
    companyEngChanged: (state, action: PayloadAction<boolean>) => {
      state.companyEng = action.payload;
    },
    clear: (state) => {
      state.companyEng = false;
    },
  },
});

export const companiesEngActions = companiesEngSlice.actions;

export const companiesEngSelectors =
  companiesEngAdapter.getSelectors<RootState>((state) => state.companyEng);

export default companiesEngSlice.reducer;
