export * from "./request";
export * from "./style";

export const REQUEST_STATUS = {
  IDLE: "idle",
  PENDING: "pending",
  FULFILLED: "fulfilled",
  ERROR: "error",
};

export type RequestStatus =
  | typeof REQUEST_STATUS.IDLE
  | typeof REQUEST_STATUS.PENDING
  | typeof REQUEST_STATUS.FULFILLED
  | typeof REQUEST_STATUS.ERROR;
