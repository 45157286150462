import type { VFC } from "react";
import React, { useEffect, useState } from "react";

import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Header from "shared/components/Header";
import Loader from "shared/components/Loader";
import Error from "shared/components/LoadingError";
import * as alumniApi from "shared/services/alumniApi";

import View from "./View";

import type { AlumnusDetail } from "shared/types";


const Detail: VFC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [alumnus, setAlumnus] = useState<AlumnusDetail>();
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async (id: string) => {
      await alumniApi
        .fetchAlumnus(id)
        .then((result) => {
          setAlumnus(result);
        })
        .catch((error) => {
          console.error(error);
          setIsError(true);
        });

      setIsLoading(false);
    };

    fetch(id);
  }, [id]);

  return (
    <div className="alumnus">
      <Helmet>
        <title>{t("title.detail")}</title>
      </Helmet>
      <Header drillDown>
        <Typography variant="h6" component="h2">
          {t("detail.label.alumni_detail")}
        </Typography>
      </Header>
      <section className="view">
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <Error />
        ) : (
          alumnus && <View alumnus={alumnus} />
        )}
      </section>
    </div>
  );
};

export default Detail;
