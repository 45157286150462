export const getOrdinalSuffix = (num: Number | null, lang: String) => {
  if (lang == "ja" || num == null) return "";

  let number_string = num.toString();
  if (number_string.endsWith("11")) return "th";
  if (number_string.endsWith("12")) return "th";
  if (number_string.endsWith("13")) return "th";
  if (number_string.endsWith("1")) return "st";
  if (number_string.endsWith("2")) return "nd";
  if (number_string.endsWith("3")) return "rd";
  return "th";
};
