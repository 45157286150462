import type { VFC } from "react";
import React from "react";


import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { useTranslation } from "react-i18next";


import {
  prefecturesSelectors,
  prefecturesActions,
  changeLanguage,
} from "./store";
import useStyles from "./styles";

import type { Prefecture } from "shared/types";

const FeachPrefecture: VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const prefectures = useAppSelector(prefecturesSelectors.selectAll);
  const prefecture = useAppSelector((state) => state.prefecture.prefecture);
  const { t, i18n } = useTranslation();
  const handleChangeOfficePrefecture = (value: string) => {
    dispatch(prefecturesActions.PrefectureChanged(value));
  };
  return (
    <div className={classes.combo}>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <TextField
          label={t("search.field.location")}
          select
          id="outlined-select-prefecture"
          margin="dense"
          defaultValue={prefecture}
          value={prefecture}
          variant="outlined"
          onChange={(e) =>
            handleChangeOfficePrefecture(e.target.value as string)
          }
        >
          <MenuItem value="">
            <em>{t("search.option.none")}</em>
          </MenuItem>
          {prefectures.map((prefecture: Prefecture, index) => (
            <MenuItem value={prefecture.id} key={index}>
              {changeLanguage(prefecture, i18n.language)}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default FeachPrefecture;
