import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1.5),
      position: "relative",
      borderBottom: "1px solid #e8e8e8",
      backgroundColor: theme.palette.background.paper,
    },
    personal: {
      display: "flex",
      alignItems: "center",
      "& .avater": {
        fontSize: "2.4rem",
        marginRight: theme.spacing(1),
      },
      "& .primary": {
        fontSize: "1rem",
        fontWeight: "bold",
      },
      "& .secondary": {
        color: theme.palette.text.secondary,
      },
    },
    content: {
      paddingTop: theme.spacing(1),
      "& .header": {
        borderLeft: `5px solid #1976d2ad`,
        padding: "3px 0px 3px 10px",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[100],
      },

      "& .subheader": {
        marginBottom: theme.spacing(1),

        "& .primary": {
          display: "inline-block",
          color: theme.palette.text.primary,
        },
        "& .secondary": {
          marginLeft: theme.spacing(2),
          display: "inline-block",
          color: theme.palette.text.secondary,
        },
      },

      "& .body": {
        padding: theme.spacing(1),
      },
    },
    box: {
      position: "relative",
      marginTop: theme.spacing(2),
      padding: theme.spacing(1.5),
      border: `solid 1px ${theme.palette.text.secondary}`,
      borderRadius: "8px",

      "& .title": {
        position: "absolute",
        display: "inline-block",
        top: "-7px",
        left: "12px",
        padding: `0 ${theme.spacing(1)}px`,
        lineHeight: "1",
        background: "#fff",
        fontSize: "0.7rem",
        color: theme.palette.text.secondary,
      },

      "& .body": {
        margin: 0,
        padding: 0,
        whiteSpace: "pre-wrap",
        color: theme.palette.text.secondary,
      },
    },
    spacing: {
      marginRight: theme.spacing(1),
    },
  }),
);

export default useStyles;
