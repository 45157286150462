import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    tag: {
      borderRadius: "4px",
      border: "2px solid #1976d2ad",
      color: "#1976d2ad",
      fontSize: "10px",
      padding: "1px",
      textAlign: "center",
      margin: "2px",
      minWidth: "48px",
      marginRight: theme.spacing(1),
    },
  }),
);

export default useStyles;
