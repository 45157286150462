import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as alumniApi from "shared/services/alumniApi";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { Loadable, GraduationFaculty } from "shared/types";

const graduationsAdapter = createEntityAdapter<GraduationFaculty>({});

const loadable = {
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  data: [],
};
const initialState = graduationsAdapter.getInitialState<{
  status: string;
  error?: string;
  graduation: string;
  listGraduationFaculty: Loadable<GraduationFaculty[]>;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  graduation: "",
  listGraduationFaculty: { ...loadable },
});

export const searchGraduation = createAsyncThunk(
  "graduations/graduationsFetched",
  async (thunkAPI) => {
    return await alumniApi.fetchGraduations();
  },
);

export const changeLanguage = (
  graduation: GraduationFaculty,
  language: string,
) => {
  if (language == "ja") {
    return graduation.name;
  }
  return graduation.nameEn;
};

const graduationsSlice = createSlice({
  name: "graduations",
  initialState: initialState,
  reducers: {
    GraduationChanged: (state, action: PayloadAction<string>) => {
      state.graduation = action.payload;
    },
    clear: (state) => {
      state.graduation = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchGraduation.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchGraduation.fulfilled, (state, { payload }) => {
        if (payload) graduationsAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchGraduation.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const graduationsActions = graduationsSlice.actions;

export const graduationsSelectors = graduationsAdapter.getSelectors<RootState>(
  (state) => state.graduation,
);

export default graduationsSlice.reducer;
