import { createTheme } from "@material-ui/core/styles";

export const defaultTheme = createTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      light: "#83C9F4",
      main: "#1976d2",
      dark: "#115293",
    },
  },
});
