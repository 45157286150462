import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up("sm")]: {
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    header: {
      marginBottom: theme.spacing(0.5),
      "& .title": {
        fontWeight: "bold",
        color: theme.palette.text.primary,
      },
      "& .primary": {
        display: "inline-block",
        color: theme.palette.text.primary,
      },
      "& .secondary": {
        display: "inline-block",
        marginLeft: theme.spacing(1),
        color: theme.palette.text.secondary,
      },
    },
  }),
);

export default useStyles;
