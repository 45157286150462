import type { VFC } from "react";
import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";

import useStyles from "./styles";

const InvalidAccount: VFC = () => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Typography variant="h4" className={classes.spacing}>
        不正なアカウント Unauthorized account
      </Typography>
      <Typography variant="body1" className={classes.spacing}>
        Googleアカウントにページにアクセスする権限がありません。
        一度Googleからログアウトして、正しいアカウントでログインしなおしてください。
      </Typography>
      <Typography variant="body1" className={classes.spacing}>
        No authority to access the page in the Google account. Log out from
        Google once and log in with the correct account.
      </Typography>
      <Link to="/" className={classes.spacing}>
        再試行 Retry
      </Link>
    </main>
  );
};

export default InvalidAccount;
