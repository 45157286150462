import type { VFC } from "react";
import React from "react";

import { Typography } from "@material-ui/core";
import { PLACEHOLDER } from "shared/constants";
import { isEmptyOrSpaces } from "shared/utils/string";

import useStyles from "./styles";

import type { Nullable } from "shared/types";

const DataItem: VFC<{
  name: string;
  value: Nullable<string>;
  placeholder?: string;
}> = ({ name, value, placeholder = PLACEHOLDER }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography component="div" className={classes.tag}>
        {name}
      </Typography>
      <Typography component="div" color="textPrimary">
        {isEmptyOrSpaces(value) ? placeholder : value}
      </Typography>
    </div>
  );
};

export default DataItem;
