import type { VFC } from "react";
import React, { useEffect } from "react";

import { Button, Hidden, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useAppDispatch, useAppSelector } from "App/hooks";
import AlumniEng from "features/AlumniEng";
import { alumnisEngActions } from "features/AlumniEng/store";
import CompanyEng from "features/CompanyEng";
import { companiesEngActions } from "features/CompanyEng/store";
import { companiesActions } from "features/CompanyList/store";
import FeachEntrance from "features/Entrance";
import { entrancesActions, searchEntrance } from "features/Entrance/store";
import FeachGraduation from "features/Graduation";
import {
  graduationsActions,
  searchGraduation,
} from "features/Graduation/store";
import FeachOccupation from "features/Occupation";
import {
  occupationsActions,
  searchOccupation,
} from "features/Occupation/store";
import FeachPrefecture from "features/Prefecture";
import {
  prefecturesActions,
  searchPrefecture,
} from "features/Prefecture/store";
import FeachSchool from "features/School";
import { schoolsActions, searchSchool } from "features/School/store";
import FeachWorkLocation from "features/WorkLocation";
import {
  workLocationsActions,
  searchWorkLocation,
} from "features/WorkLocation/store";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Header from "shared/components/Header";
import Logo from "shared/components/Logo";
import Symbol from "shared/components/Symbol";

import { searchActions } from "./store";
import useStyles from "./styles";
import { REQUEST_STATUS } from "../../shared/constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

const Search: VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const companyName = useAppSelector((state) => state.search.companyName);
  const prefecture = useAppSelector((state) => state.prefecture.prefecture);
  const occupation = useAppSelector((state) => state.occupation.occupation);
  const workLocation = useAppSelector(
    (state) => state.workLocation.workLocation,
  );
  const entrancePeriod = useAppSelector((state) => state.entrance.entrance);
  const graduationFaculty = useAppSelector(
    (state) => state.graduation.graduation,
  );
  const studentStatus = useAppSelector((state) => state.school.school);
  const companyEng = useAppSelector((state) => state.companyEng.companyEng);
  const alumniEng = useAppSelector((state) => state.alumniEng.alumniEng);
  const [tabValue, setTabValue] = React.useState(0);
  const { t, i18n } = useTranslation();
  const listPrefecture = useAppSelector(
    (state) => state.prefecture.listPrefecture,
  );
  const listOccupation = useAppSelector(
    (state) => state.occupation.listOccupation,
  );
  const listWorkLocation = useAppSelector(
    (state) => state.workLocation.listWorkLocation,
  );
  const listEntrancePeriod = useAppSelector(
    (state) => state.entrance.listEntrancePeriod,
  );
  const listGraduationFaculty = useAppSelector(
    (state) => state.graduation.listGraduationFaculty,
  );
  const listStudentStatus = useAppSelector(
    (state) => state.school.listStudentStatus,
  );

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    dispatch(searchActions.TabChanged(newValue.toString()));
  };

  useEffect(() => {
    const hash = window.location.hash.substr(1);
    const params = new URLSearchParams("?" + hash);
    const lang = params.get("lang");

    if (lang != null) {
      i18n.changeLanguage(lang);
      window.location.hash = "";
    }

    if (listPrefecture.status === REQUEST_STATUS.IDLE) {
      dispatch(searchPrefecture());
    }
    if (listOccupation.status === REQUEST_STATUS.IDLE) {
      dispatch(searchOccupation());
    }
    if (listWorkLocation.status === REQUEST_STATUS.IDLE) {
      dispatch(searchWorkLocation());
    }
    if (listEntrancePeriod.status === REQUEST_STATUS.IDLE) {
      dispatch(searchEntrance());
    }
    if (listGraduationFaculty.status === REQUEST_STATUS.IDLE) {
      dispatch(searchGraduation());
    }
    if (listStudentStatus.status === REQUEST_STATUS.IDLE) {
      dispatch(searchSchool());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSearch = () => {
    dispatch(companiesActions.clear());
    if (tabValue == 0) {
      // 企業から探す
      const searchParams = [
        ["companyName", companyName],
        ["prefecture", prefecture],
        ["companyEng", companyEng + ""],
        ["language", i18n.language],
      ];
      history.push({
        pathname: "/companies",
        search: new URLSearchParams(searchParams).toString(),
      });
    } else if (tabValue == 1) {
      // 先輩の特徴から探す
      const searchParams = [
        ["occupation", occupation],
        ["prefecture", workLocation],
        ["entrancePeriod", entrancePeriod],
        ["graduationFaculty", graduationFaculty],
        ["studentStatus", studentStatus],
        ["alumniEng", alumniEng + ""],
        ["language", i18n.language],
      ];
      history.push({
        pathname: "/alumni",
        search: new URLSearchParams(searchParams).toString(),
      });
    }
  };

  return (
    <div className="search">
      <Helmet>
        <title>{t("title.search")}</title>
      </Helmet>
      <Header logo={false}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.title}>{t("search.field.title")}</div>
      </Header>

      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          aria-label="disabled tabs example"
        >
          <Tab label={t("search.tag.company")} />
          <Tab label={t("search.tag.alumni")} />
        </Tabs>
      </Paper>

      <TabPanel value={tabValue} index={0}>
        <section className={classes.form}>
          <div className="fields">
            <TextField
              id="search-field-company-name"
              fullWidth
              label={t("search.field.name")}
              className={classes.field}
              variant="outlined"
              margin="dense"
              value={companyName}
              onChange={(e) =>
                dispatch(searchActions.CompanyNameChanged(e.target.value))
              }
            />
          </div>
          <FeachPrefecture />
          <CompanyEng />
        </section>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <div className="search">
          <section className={classes.form}>
            <FeachOccupation />
            <FeachWorkLocation />
            <FeachEntrance />
            <FeachGraduation />
            <FeachSchool />
            <AlumniEng />
          </section>
        </div>
      </TabPanel>

      <div className={classes.actions}>
        <Button
          variant="contained"
          className={classes.clear}
          onClick={() => {
            dispatch(searchActions.clear());
            dispatch(prefecturesActions.clear());
            dispatch(occupationsActions.clear());
            dispatch(workLocationsActions.clear());
            dispatch(entrancesActions.clear());
            dispatch(prefecturesActions.clear());
            dispatch(graduationsActions.clear());
            dispatch(schoolsActions.clear());
            dispatch(companiesEngActions.clear());
            dispatch(alumnisEngActions.clear());
          }}
        >
          {t("search.action.clear")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickSearch}
          className={classes.search}
        >
          {t("search.action.search")}
        </Button>
      </div>

      <Hidden smUp implementation="css">
        <div className={classes.symbol}>
          <Symbol />
        </div>
      </Hidden>
    </div>
  );
};

export default Search;
