import type { VFC } from "react";
import React from "react";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { useAppSelector } from "App/hooks";
import { useAppDispatch } from "App/hooks";
import { useTranslation } from "react-i18next";

import { alumnisEngActions } from "./store";

const AlumniEng: VFC = () => {
  const alumniEng = useAppSelector((state) => state.alumniEng.alumniEng);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(alumnisEngActions.AlumniEngChanged(event.target.checked));
  };
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={alumniEng}
            onChange={handleChange}
            name="checkedAlumni"
            color="primary"
          />
        }
        label={t("search.field.alumni_eng")}
      />
    </FormGroup>
  );
};
export default AlumniEng;
