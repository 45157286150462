import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      paddingLeft: "5px",
    },
    title: {
      fontSize: "15px",
      fontWeight: "bold",
      color: "#1c5ba8",
      paddingLeft: "3px",
    },
    form: {
      padding: `${theme.spacing(3)}px ${theme.spacing(1.5)}px`,
      borderBottom: "1px solid #e8e8e8",
      backgroundColor: theme.palette.background.paper,
    },
    field: {
      marginBottom: theme.spacing(3),
    },
    actions: {
      display: "flex",
    },
    clear: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    search: {
      flexGrow: 1,
      marginRight: theme.spacing(2),
    },
    symbol: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      backgroundColor: theme.palette.background.default,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    formControlOut: {
      margin: theme.spacing(5),
      marginLeft: theme.spacing(5),
      marginBottom: theme.spacing(5),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    combo: {
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(1),
    },
  }),
);

export default useStyles;
