import type { ReactNode, VFC } from "react";
import React from "react";

import { Typography } from "@material-ui/core";
import { PLACEHOLDER } from "shared/constants";

import useStyles from "./styles";

interface Props {
  name: string;
  value: ReactNode;
  placeholder?: string;
}

const DataItem: VFC<Props> = ({ name, value, placeholder = PLACEHOLDER }) => {
  const classes = useStyles();

  if (!value) {
    value = placeholder;
  }

  return (
    <div className={classes.root}>
      <Typography component="div" className={classes.name}>
        {name}
      </Typography>
      {typeof value === "string" ? (
        <Typography component="div" className={classes.value}>
          {value}
        </Typography>
      ) : (
        <div className={classes.value}>{value}</div>
      )}
    </div>
  );
};

export default DataItem;
