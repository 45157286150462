import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as alumniApi from "shared/services/alumniApi";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { Loadable, EntrancePeriod } from "shared/types";

export const entrancesAdapter = createEntityAdapter<EntrancePeriod>({});

const loadable = {
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  data: [],
};
const initialState = entrancesAdapter.getInitialState<{
  status: string;
  error?: string;
  entrance: string;
  listEntrancePeriod: Loadable<EntrancePeriod[]>;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  entrance: "",
  listEntrancePeriod: { ...loadable },
});

export const searchEntrance = createAsyncThunk(
  "entrances/entrancesFetched",
  async (thunkAPI) => {
    return await alumniApi.fetchEntrances();
  },
);

export const changeLanguage = (entrance: EntrancePeriod, language: string) => {
  if (language == "ja") {
    return entrance.name;
  }
  return entrance.nameEn;
};

const entrancesSlice = createSlice({
  name: "entrances",
  initialState: initialState,
  reducers: {
    entranceChanged: (state, action: PayloadAction<string>) => {
      state.entrance = action.payload;
    },
    clear: (state) => {
      state.entrance = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchEntrance.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchEntrance.fulfilled, (state, { payload }) => {
        if (payload) entrancesAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchEntrance.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const entrancesActions = entrancesSlice.actions;

export const entrancesSelectors = entrancesAdapter.getSelectors<RootState>(
  (state) => state.entrance,
);

export default entrancesSlice.reducer;
