import type { VFC } from "react";
import React from "react";

import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const Symbol: VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <img
      src={`${t("path.logo_footer")}`}
      alt="創価大学シンボル"
      width="160px"
      className={classes.symbol}
    />
  );
};

export default Symbol;
