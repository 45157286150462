import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    view: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1),
    },
    spacing: {
      padding: theme.spacing(1),
    },
    ul: {
      paddingRight: theme.spacing(2),
    },
  }),
);

export default useStyles;
