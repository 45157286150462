import { handleResponse } from "shared/utils/apiUtils";

import type { Company, Prefecture } from "shared/types";

export const searchCompanies = (
  params: URLSearchParams,
): Promise<Company[]> => {
  return fetch("/api/companies/search?" + params)
    .then(handleResponse)
    .then((response) => response.json());
};
export const fetchPrefectures = (): Promise<Prefecture[]> => {
  return fetch("/api/Prefectures/Search")
    .then(handleResponse)
    .then((response) => response.json());
};
