import type { ReactNode, VFC } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { IconButton } from "@material-ui/core";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { showDrawer } from "App/Shell/store";

import useStyles from "./styles";
import LanguageChangeButton from "../LanguageChangeButton";
import Logo from "../Logo";

interface Props {
  children: ReactNode;
  drillDown?: boolean;
  divider?: boolean;
  logo?: boolean;
}

const Header: VFC<Props> = ({
  children,
  drillDown = false,
  divider = true,
  logo = true,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <header
      className={classes.header}
      style={divider ? { borderBottom: "1px solid #e8e8e8" } : undefined}
    >
      {drillDown ? (
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIcon />
        </IconButton>
      ) : null}
      {logo && (
        <div className={classes.logo}>
          <Logo />
        </div>
      )}

      {children}
      <LanguageChangeButton />
      <div className={classes.menuButton}>
        <IconButton onClick={() => dispatch(showDrawer())}>
          <MenuIcon />
        </IconButton>
      </div>
    </header>
  );
};

export default Header;
