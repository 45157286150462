import React from "react";

import ReactDOM from "react-dom";
import ReactGA4 from "react-ga4";

import "./index.css";
import App from "./App";

import type { SetupWorkerApi } from "msw";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_USE_MOCK_API === "true"
) {
  const { worker } = require("./mocks/browser") as { worker: SetupWorkerApi };
  worker.start({ onUnhandledRequest: "bypass" });
}

if (!ReactGA4.isInitialized) {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID === undefined) {
    throw new Error("REACT_APP_GA_MEASUREMENT_ID is not defined");
  }
  ReactGA4.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
