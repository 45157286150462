import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as alumniApi from "shared/services/alumniApi";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "App/store";
import type { Loadable, StudentStatus } from "shared/types";

const schoolsAdapter = createEntityAdapter<StudentStatus>({});

const loadable = {
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  data: [],
};

const initialState = schoolsAdapter.getInitialState<{
  status: string;
  error?: string;
  school: string;
  listStudentStatus: Loadable<StudentStatus[]>;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  school: "",
  listStudentStatus: { ...loadable },
});

export const searchSchool = createAsyncThunk(
  "schools/schoolsFetched",
  async (thunkAPI) => {
    return await alumniApi.fetchSchools();
  },
);

export const changeLanguage = (school: StudentStatus, language: string) => {
  if (language == "ja") {
    return school.name;
  }
  return school.nameEn;
};

const schoolsSlice = createSlice({
  name: "schools",
  initialState: initialState,
  reducers: {
    SchoolChanged: (state, action: PayloadAction<string>) => {
      state.school = action.payload;
    },
    clear: (state) => {
      state.school = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchSchool.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchSchool.fulfilled, (state, { payload }) => {
        if (payload) schoolsAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchSchool.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const schoolsActions = schoolsSlice.actions;

export const schoolsSelectors = schoolsAdapter.getSelectors<RootState>(
  (state) => state.school,
);

export default schoolsSlice.reducer;
