import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { REQUEST_STATUS } from "shared/constants";
import * as alumniApi from "shared/services/alumniApi";

import type { RootState } from "App/store";
import type { Alumnus } from "shared/types";

const alumniAdapter = createEntityAdapter<Alumnus>({
  sortComparer: (a, b) => {
    if (a.collegeTerm == null) return 1;
    if (b.collegeTerm == null) return -1;
    return a.collegeTerm < b.collegeTerm ? 1 : -1;
  },
});

const initialState = alumniAdapter.getInitialState<{
  status: string;
  error?: string;
}>({
  status: REQUEST_STATUS.IDLE,
  error: undefined,
});

export const searchAlumni = createAsyncThunk(
  "alumni/alumniFetched",
  async (searchParams: string, thunkAPI) => {
    const params = new URLSearchParams(searchParams);
    return await alumniApi.searchAlumni(params);
  },
);

const alumniSlice = createSlice({
  name: "alumni",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchAlumni.pending, (state) => {
        state.status = REQUEST_STATUS.PENDING;
      })
      .addCase(searchAlumni.fulfilled, (state, { payload }) => {
        if (payload) alumniAdapter.setAll(state, payload);
        state.status = REQUEST_STATUS.FULFILLED;
      })
      .addCase(searchAlumni.rejected, (state, action) => {
        state.status = REQUEST_STATUS.ERROR;
        state.error = action.error.message;
      });
  },
});

export const alumniActions = alumniSlice.actions;

export const alumniSelectors = alumniAdapter.getSelectors<RootState>(
  (state) => state.alumni,
);

export default alumniSlice.reducer;
