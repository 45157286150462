import { makeStyles } from "@material-ui/core/styles";
import { HEADER_HEIGHT } from "shared/constants";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    marginTop: HEADER_HEIGHT,
  },
}));

export default useStyles;
