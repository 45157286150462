import {
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { REQUEST_STATUS } from "../../shared/constants";

import type {
  PayloadAction} from "@reduxjs/toolkit";
import type {
  Loadable,
  Prefecture,
  EntrancePeriod,
  Occupation,
  WorkLocation,
  GraduationFaculty,
  StudentStatus,
} from "shared/types";


const searchesAdapter = createEntityAdapter<Prefecture>({
  sortComparer: (a, b) => {
    if (a.id == null) return 1;
    if (b.name == null) return -1;
    return a.id.localeCompare(b.name, "ja");
  },
});

const loadable = {
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  data: [],
};

const initialState = searchesAdapter.getInitialState<{
  companyName: string;
  prefecture: string;
  occupation: string;
  workLocation: string;
  entrancePeriod: string;
  graduationFaculty: string;
  studentStatus: string;
  language: string;
  tabValue: string;
  status: string;
  error?: string;
  listPrefecture: Loadable<Location[]>;
  listOccupation: Loadable<Occupation[]>;
  listWorkLocation: Loadable<WorkLocation[]>;
  listEntrancePeriod: Loadable<EntrancePeriod[]>;
  listGraduationFaculty: Loadable<GraduationFaculty[]>;
  listStudentStatus: Loadable<StudentStatus[]>;
}>({
  companyName: "",
  prefecture: "",
  occupation: "",
  workLocation: "",
  entrancePeriod: "",
  graduationFaculty: "",
  studentStatus: "",
  language: "0",
  tabValue: "",
  status: REQUEST_STATUS.IDLE,
  error: undefined,
  listPrefecture: { ...loadable },
  listOccupation: { ...loadable },
  listWorkLocation: { ...loadable },
  listEntrancePeriod: { ...loadable },
  listGraduationFaculty: { ...loadable },
  listStudentStatus: { ...loadable },
});

const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    CompanyNameChanged: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    PrefectureChanged: (state, action: PayloadAction<string>) => {
      state.prefecture = action.payload;
    },
    OccupationChanged: (state, action: PayloadAction<string>) => {
      state.occupation = action.payload;
    },
    WorkLocationChanged: (state, action: PayloadAction<string>) => {
      state.workLocation = action.payload;
    },
    EntrancePeriodChanged: (state, action: PayloadAction<string>) => {
      state.entrancePeriod = action.payload;
    },
    GraduationFacultyChanged: (state, action: PayloadAction<string>) => {
      state.graduationFaculty = action.payload;
    },
    StudentStatusChanged: (state, action: PayloadAction<string>) => {
      state.studentStatus = action.payload;
    },
    LanguageChanged: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    TabChanged: (state, action: PayloadAction<string>) => {
      state.tabValue = action.payload;
    },
    clear: (state) => {
      state.companyName = "";
      state.prefecture = "";
      state.occupation = "";
      state.workLocation = "";
      state.entrancePeriod = "";
      state.graduationFaculty = "";
      state.studentStatus = "";
      state.language = "0";
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
