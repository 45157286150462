import { makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  symbol: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
  },
  drawerPaper: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
    },
  },
  scrollBar: {
    position: "absolute",
    right: 0,
    left: 0,
    top: 13,
    margin: "auto",
    width: 50,
    padding: 1.5,
    borderRadius: "6px",
  },
  close: { position: "absolute", right: 0 },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
