import type { VFC } from "react";
import React from "react";

import { CircularProgress } from "@material-ui/core";

import useStyles from "./styles";

const Loader: VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress size={30} />
    </div>
  );
};

export default Loader;
