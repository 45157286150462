import type { VFC } from "react";
import React from "react";

import { Button, Typography } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";
import { useTranslation } from "react-i18next";

import useStyles from "./styles";

const LoadingError: VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <Typography component="div">{t("loading.label.no1")}</Typography>
      <Button onClick={handleReload} startIcon={<ReplayIcon />}>
        {t("loading.label.no2")}
      </Button>
    </div>
  );
};

export default LoadingError;
