import type { VFC } from "react";
import React, { useEffect } from "react";

import { List, Typography } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import Header from "shared/components/Header";
import Loader from "shared/components/Loader";
import Error from "shared/components/LoadingError";
import { REQUEST_STATUS } from "shared/constants";

import ListItem from "./ListItem";
import { companiesSelectors, searchCompany } from "./store";
import useStyles from "./styles";

const SearchResult: VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const companyIds = useAppSelector(companiesSelectors.selectIds);
  const requestStatus = useAppSelector((state) => state.companies.status);
  const isLoading = requestStatus === REQUEST_STATUS.PENDING;
  const isError = requestStatus === REQUEST_STATUS.ERROR;
  const MAX_FETCH_SIZE = 200;
  const { t } = useTranslation();

  useEffect(() => {
    if (history.action === "PUSH" || !companyIds.length) {
      dispatch(searchCompany(location.search))
        .then(unwrapResult)
        .then((result) => {
          if (result.length >= MAX_FETCH_SIZE) {
            enqueueSnackbar(
              `${t("search.result.before")}${MAX_FETCH_SIZE}${t(
                "search.result.after",
              )}`,
              {
                variant: "info",
              },
            );
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, history]);

  const listCount = (
    <Typography
      variant="h6"
      className={classes.listCount}
      color="textSecondary"
    >
      {`${companyIds.length} ${
        companyIds.length >= MAX_FETCH_SIZE
          ? `${t("list.more")}`
          : `${t("list.results")}`
      }`}
    </Typography>
  );

  return (
    <div className="root">
      <Helmet>
        <title>{t("title.company")}</title>
      </Helmet>
      <Header drillDown>
        <Typography variant="h6" component="h1" className={classes.heading}>
          {t("search.field.company_seniors")}
        </Typography>
        {!isError && !isLoading && listCount}
      </Header>
      <section className="view">
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <Error />
        ) : (
          <List disablePadding>
            {companyIds.map((id) => (
              <ListItem key={id} id={id} />
            ))}
          </List>
        )}
      </section>
    </div>
  );
};

export default SearchResult;
