import type { VFC } from "react";
import React from "react";

import { ListItem as MuiListItem, Typography } from "@material-ui/core";
import { useAppSelector } from "App/hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import DataItem from "shared/components/DataItem";
import { PLACEHOLDER } from "shared/constants";
import { getOrdinalSuffix } from "shared/utils/grammar";

import useStyles from "./styles";
import { alumniSelectors } from "../store";

import type { EntityId } from "@reduxjs/toolkit";
import type { Alumnus } from "shared/types";

const ListItem: VFC<{ id: EntityId }> = ({ id }) => {
  const classes = useStyles();
  const history = useHistory();
  const alumni = useAppSelector((state) =>
    alumniSelectors.selectById(state, id),
  );
  const { t, i18n } = useTranslation();

  if (!alumni) return null;

  const handleClick = (id: EntityId) => {
    history.push({ pathname: `/alumni/${id}` });
  };

  const setCompany = (alumnus: Alumnus) => {
    if (
      i18n.language == "ja" ||
      alumnus.companyNameEn == "" ||
      alumnus.companyNameEn == null
    ) {
      if (alumnus.belong == "" || alumnus.belong == null) {
        return alumnus.companyName;
      }
      return alumnus.companyName + " " + alumnus.belong;
    }
    return alumnus.companyNameEn + " " + alumnus.belong;
  };
  const setAlumni = (alumnus: Alumnus) => {
    if (
      i18n.language == "ja" ||
      alumnus.nameEn == "" ||
      alumnus.nameEn == null
    ) {
      return alumnus.name;
    }
    return alumnus.nameEn;
  };
  const setJobType = (alumnus: Alumnus) => {
    if (
      i18n.language == "ja" ||
      alumnus.jobTypeEn == "" ||
      alumnus.jobTypeEn == null
    ) {
      return alumnus.jobType;
    }
    return alumnus.jobTypeEn;
  };
  const setPrefecture = (alumnus: Alumnus) => {
    if (
      i18n.language == "ja" ||
      alumnus.officePrefectureEn == "" ||
      alumnus.officePrefectureEn == null
    ) {
      return alumnus.officePrefecture;
    }
    return alumnus.officePrefectureEn;
  };
  const setCollegeGrad = (alumnus: Alumnus) => {
    if (i18n.language == "ja") {
      if (
        alumnus.collegeGradDepartment == "" ||
        alumnus.collegeGradDepartment == null
      ) {
        return alumnus.collegeGradFaculty;
      }
      if (
        alumnus.collegeGradFaculty == "" ||
        alumnus.collegeGradFaculty == null
      ) {
        return alumnus.collegeGradDepartment;
      }
      return alumnus.collegeGradFaculty + " " + alumnus.collegeGradDepartment;
    }
    // バックエンド側で英語の学部・学科がない場合は日本語の学部・学科をセット済
    if (
      alumnus.collegeGradDepartmentEn == "" ||
      alumnus.collegeGradDepartmentEn == null
    ) {
      return alumnus.collegeGradFacultyEn;
    }
    if (
      alumnus.collegeGradFacultyEn == "" ||
      alumnus.collegeGradFacultyEn == null
    ) {
      return alumnus.collegeGradDepartmentEn;
    }
    return alumnus.collegeGradFacultyEn + " " + alumnus.collegeGradDepartmentEn;
  };

  return (
    <MuiListItem
      divider
      role="listitem"
      alignItems="flex-start"
      onClick={() => handleClick(alumni.id)}
      className={classes.root}
    >
      <div className={classes.header}>
        <Typography component="div" className="title">
          {`${setCompany(alumni)}`.trim()}
        </Typography>
        <Typography component="div" className="primary">
          {setAlumni(alumni)}
        </Typography>
        <Typography component="div" className="secondary">
          {`${alumni.collegeTerm || PLACEHOLDER}`}
          {getOrdinalSuffix(alumni.collegeTerm, i18n.language)}
          {t("search.field.period")}
        </Typography>
      </div>
      <div className="item-body">
        <DataItem name={t("search.field.job")} value={setJobType(alumni)} />
        <DataItem name={t("search.field.director")} value={alumni.post} />
        <DataItem
          name={t("search.field.grad")}
          value={`${setCollegeGrad(alumni)}`.trim()}
        />
        <DataItem name={t("search.field.area")} value={setPrefecture(alumni)} />
      </div>
    </MuiListItem>
  );
};

export default ListItem;
