import { createStyles, makeStyles } from "@material-ui/core/styles";

import type { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginBottom: theme.spacing(0.5),
    },
    name: {
      verticalAlign: "top",
      display: "inline-block",
      color: theme.palette.text.secondary,
      width: "100px",
      flexShrink: 0,
    },
    value: {
      display: "inline-block",
      whiteSpace: "pre-wrap",
      color: theme.palette.text.primary,
      flexGrow: 1,
    },
  }),
);

export default useStyles;
